html {
  height: 100%;
}

body {
  height: inherit;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3 {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}

#root {
  height: inherit;
}

.inherit-height {
  height: inherit;
}

.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}

.welcome-page .logo {
  width: 500px;
}

.welcome-page ul {
  columns: 2;
}

.welcome-page .get-started {
  display: flex;
  flex-grow: 50;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-page .get-started button {
  border: #2f5597 4px solid;
  cursor: pointer;
  border-radius: 8px;
  background-color: #2f5597;
  color: white;
  font: 20px Helvetica Neue,Helvetica,Arial,sans-serif;
  padding: 15px;
}

.welcome-page p {
  width: 40rem;
}

.welcome-page .get-started button:active {
  background-color: #203763;
  border-color: #203763;
}

.welcome-page Link {
  display: flex;
}

.welcome-page li {
  margin-right: 40px;
}

.finish-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}

.finish-page h1 {
  color: #2f5597;
}

.info {
  background-color: #8faadc;
  padding: 30px;
  color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info::before {
  content: "ⓘ";
  color: white;
  font-size: 40px;
  font-weight: bolder;
  margin: 0 30px 0 0px;
  display: block;
  float: left;
}

.part-page {
  padding: 0 2rem 3rem;
}

.part-page::after {
  content:"";
  display:block;
  clear:both;
}

.footer {
  margin-top: 2rem;
}

.part-page h1, .part-page h2 {
  color: #2f5597;
}

.part-page > ol {
  list-style: none;
  padding: 0;
}

.part-page > ol > li {
  counter-increment: step-counter;
  margin-top: 3rem;
}

.part-page > ol > li::before {
  content: counter(step-counter, upper-latin) ". ";
  color: red;
  display: inline;
  font-size: 28px;
  color: #2f5597;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-weight: bolder;
}

.part-page h1 {
  font-size: 28px;
  display: inline;
}

.part-page > ul h1 {
  font-size: 20px;
}

.file-upload-component ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 15px;
}

.file-upload-component ul li {
  display: inline-block;
}

.file-upload-component div {
  margin: 2rem 1rem 1rem;
}

button {
  background-color: #8faadc;
  border: #8faadc 1px solid;
  border-radius: 5px;
  margin: 2px;
  color: white;
  cursor: pointer;
}

button:active {
  background-color: #658bce;
  border-color: #658bce;
}

.file-icon {
  margin: 0 7px;
}

.file-icon .icon {
  background-image: url('../public/file-icon.png');
  background-size: cover;
  width: 40px;
  height: 50px;
  margin: 0 auto;
}

div.warning {
  color: orangered;
  font-style: italic;
}

button.nav {
  border: #2f5597 1px solid;
  cursor: pointer;
  border-radius: 8px;
  background-color: #2f5597;
  color: white;
  font: 20px Helvetica Neue,Helvetica,Arial,sans-serif;
  padding: 10px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.questions ul {
  padding-left: 0;
}

.questions li {
  list-style-type: none;
  margin-bottom: 1rem;
}

.question span {
  display: block;
  font-family: Consolas, monaco, monospace;
  font-size: 14px;
}

.question input {
  border: black 1px solid;
    outline: none;
}
